import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

const Empty = () => (
  <Layout>
    <SEO title="Empty" />
  </Layout>
);

export default Empty;
